html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif; */
  font-family: Inter, system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ann {
  width: Fixed (361px) px;
  height: Hug (94px) px;
  padding: 16px 92px 16px 92px;
  gap: 0px;
  border-radius: 6px 0px 0px 0px;
  justify: space-between;
  opacity: 0px;
}

.title-update {
  bottom: 12px;
  position: relative;
  padding-right: 10px;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: left;
}

.title-icon {
  border-radius: 10px;
  margin-top: 6px;
}

.scroll {
  overflow: scroll;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
