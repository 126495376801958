.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ul.a {
  list-style-position: outside;
}

.image-capture {
  border-radius: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* width: 550;
  height: 350; */
}

.ann-banner {
  font-family: Manrope;
  font-size: 56px;
  font-weight: 800;
  line-height: 76.5px;
  letter-spacing: -0.01em;
  text-align: left;
}

.loading-bg-video {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.loginLogo {
  width: 200px !important;
  margin: 10px !important;
}

video {
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover; /* optional, to control how the video fits within the element */
}

.interview-progress {
  width: 200px;
  margin-right: 25px;
  align-self: center;
}
